import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import useFetch from '../../hooks/useFetch';
import { useLocation, useNavigate } from 'react-router';
import Listing from '../../components/Listing';

export default function TeamCustomers() {
	let navigate = useNavigate();
	let location = useLocation();

	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [filter, setFilter] = useState({
		limit: 7,
		skip: 0,
		pages: 0,
		sort: 'date.desc',
	});

	const [search, setSearch] = useState(null);
	const [sort, setSort] = useState('date.desc');
	const [pages, setPages] = useState(0);
	const [selectedTabs, setSelectedTabs] = useState({
		left: 'all',
	});

	const topOfPage = useRef(null);

	const { teamState } = useContext(CurrentTeamContext);

	const { data, fullData, reqLoading, fetch } = useFetch(
		`${config.apiv1}/customer/customers.read/${teamState.currentTeam.teamUuid}?${search ? `search=${search}` : ''}`
	);

	const updateDebounceSearch = useCallback(
		debounce((searchValue) => {
			setSearch(searchValue);
			setLoading(false);
		}),
		[]
	);

	function handlePagination(data) {
		let selected = data.selected;
		let newSkip = Math.ceil(selected * filter?.limit);
		setLoading(true);
		handleFilter(newSkip, 'skip');
	}

	function resetPagination() {
		let paginationBtns = document.getElementsByClassName('paginationPage');
		for (let i = 0; i < paginationBtns.length; i++) {
			paginationBtns[i]?.classList.remove('paginationActive');
		}
		paginationBtns[0]?.classList.add('paginationActive');
	}

	function handleFilter(value, name) {
		if (name === 'status') {
			rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: { status: value } });
		}
		if (name !== 'skip') {
			resetPagination();
		}

		if (typeof value === 'object' && Object.keys(value)?.length !== 0) {
			let multiStatus = '';

			for (let field in value) {
				let statusText = field;
				if (value[field] === true && filterStatuses.includes(statusText)) {
					multiStatus = multiStatus + makeFirstCharUpper(statusText) + ', ';
				}
			}

			if (multiStatus.length > 0) {
				multiStatus = multiStatus.slice(0, -2);
				return setFilter((filter) => ({
					...filter,
					...value,
					multiStatus: multiStatus,
					status: null,
				}));
			} else {
				return setFilter((filter) => ({
					...filter,
					...value,
					status: null,
				}));
			}
		}
		setFilter((filter) => ({
			...filter,
			[name]: value,
		}));
	}

	const handleFilterSelect = (value, name) => {
		setFilter((filter) => ({
			...filter,
			[name]: value?.value,
		}));
	};

	function debounce(cb, delay = 400) {
		let timeout;
		return (...args) => {
			setLoading(true);
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	function handlePagination(data) {
		setLoaded(false);
		const gridItemLimit = 15;
		let selected = data.selected;
		let newSkip = Math.ceil(selected * gridItemLimit);
		fetch(
			`${config.apiv1}/customer/customers.read/${teamState.currentTeam.teamUuid}?skip=${newSkip}&sort=${sort}${
				search ? `search=${search}` : ''
			}`
		);
	}

	function openFilter() {
		const contentType = 3;
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: { status: filter?.status || presetStatus } });
		rightSidebarDispatch({
			type: 'SET_CALLBACK',
			payload: (filter) => {
				handleFilter(filter);
				rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
			},
		});
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: contentType });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
	}

	async function buildURLAndFetch() {
		const url = new URL(`${config.apiv1}/customer/customers.read/${teamState.currentTeam.teamUuid}`);
		filter?.sort?.split('.')[0] === 'date' && url.searchParams.set('sortDate', filter?.sort?.split('.')[1]);
		filter?.sort?.split('.')[0] === 'team' && url.searchParams.set('sortAlphabet', filter?.sort?.split('.')[1]);

		search && url.searchParams.set('search', search);
		filter?.limit && url.searchParams.set('limit', filter?.limit);
		filter?.skip && url.searchParams.set('skip', filter?.skip);

		let res = await fetch(url.href, null, true);

		if (res?.ok) {
			setPages(res?.data && res.data.length > 0 ? Math.ceil(res?.totalDocuments / filter?.limit) : 0);
		} else {
			setPages(1);
		}
		setLoading(false);
	}

	useEffect(() => {
		let mounted = true;
		if (mounted && reqLoading === false) {
			const onePage = 0;
			let sortedArr =
				data &&
				data?.sort((a, b) => {
					if (a.createdAt < b.createdAt) {
						return 1;
					}
					if (a.createdAt > b.createdAt) {
						return -1;
					}
					return 0;
				});
			if (!data) {
				setLoaded(true);
				setPages(0);
				return;
			}
			setPages(fullData ? Math.ceil(fullData?.totalDocuments / 15) : onePage);
		}
		return () => (mounted = false);
	}, [reqLoading, data, fullData]);

	useEffect(() => {
		buildURLAndFetch();
	}, [teamState.reload, filter, search, location]);

	return (
		<>
			<div ref={topOfPage}></div>
			<Listing
				openFilter={openFilter}
				handlePagination={handlePagination}
				handleSearch={updateDebounceSearch}
				loading={loading}
				handleFilterSelect={handleFilterSelect}
				handleFilter={handleFilter}
				setSelectedTabs={setSelectedTabs}
				selectedTabs={selectedTabs}
				// options={options}
				listItems={data}
				filter={filter}
				pages={pages}
				type='customers'
			/>
		</>
	);
}
