import React, { useContext } from 'react';
import Button from '../../Button';
import SelectCustom from '../../Select/SelectCustom';
import { ArrowNarrowRightIcon } from '../../SVGIcons/SVGIcons';
import { parseToOptions } from '../../../helpers';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import sortOptions from '../../Options/sortOptions';

export default function CustomersFilterBar(props) {
	const { filter, handleFilter, handleSearch, openFilter, handleFilterSelect } = props || {};

	const { teamState } = useContext(CurrentTeamContext);

	return (
		<div className='filterBar'>
			<div className='field'>
				<h4 className='label'>Search for customer</h4>
				<input
					className='textFieldInput'
					onChange={(e) => {
						handleSearch(e.target.value);
					}}
				/>
			</div>

			<div className='field'>
				<h4 className='label'>Due date</h4>
				<SelectCustom
					htmlOptions={sortOptions}
					placeholder={'Select...'}
					fieldName='sort'
					callback={handleFilterSelect}
					value={filter?.sort}
					width={300}
				/>
			</div>
		</div>
	);
}
