import React, { useContext, useEffect, useState } from 'react';
import {
	AtIcon,
	BarcodeIcon,
	CalendarIcon,
	ChevronDownIcon,
	LinkIcon,
	MapPinIcon,
	PrintIcon,
	TagIcon,
	TargetIcon,
	TruckRightIcon,
} from '../../components/SVGIcons/SVGIcons';
import { dateConverter, isValidURL, parseTrackingStatus, replaceString, sliceString } from '../../helpers';
import { itemListTemp, packLabelTemp } from '../../templates/templates';
import PageWrapper from '../../components/Invoice/PageWrapper';
import { useLocation, useNavigate, useParams } from 'react-router';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import StopwatchCont from '../../components/ModalListing/StopwatchCont';
import ItemsList from './ItemsList';
import MemberInfo from '../../components/MemberInfo';
import Axios from 'axios';
import Loading from '../../components/Loading/Loading';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import usePost from '../../hooks/usePost';
import { ModalContext } from '../../contexts/ModalContext';

export default function PackageDetails() {
	const [pack, setPack] = useState({});
	const [loading, setLoading] = useState(true);
	const [reload, setReload] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const { teamState } = useContext(CurrentTeamContext);

	let location = useLocation();
	let params = useParams();

	const { showModal } = useModalsGlobal();

	let { flashFadeModal } = useContext(ModalContext);

	let navigate = useNavigate();

	const statuses = {
		PPREPARING: 1,
		IN_TRANSIT: 2,
		DELIVERED: 3,
	};

	const { data, fetch, reqLoading } = useFetch(`${config.apiv1}/package/package.read/${params?.packageUuid}`);

	const { post } = usePost();

	let addressString = pack?.address?.addressLine1
		? `${pack?.address?.name ? pack?.address?.name : ''}${
				pack?.address?.addressLine1 ? `, ${pack?.address?.addressLine1}` : ''
		  }${pack?.address?.county ? `, ${pack?.address?.county}` : ''}${
				pack?.address?.postalCode ? `, ${pack?.address?.postalCode}` : ''
		  }`
		: '';

	async function postPackageStatus(pack, status) {
		let res = await post({ packageUuid: pack.packageUuid, status }, `${config.apiv1}/location/location.create`);
		console.log(res);
		if (res?.data?.status === status) {
			setPack((pack) => ({
				...pack,
				locations: [...pack.locations, res.data],
				last: res.data,
			}));
			flashFadeModal('The package was marked as delivered');
		}
	}

	function getFilePath(file) {
		let res = Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			return url;
		});
		return res;
	}

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const handleQRScan = (pack, teamUuid) => {
		if (teamUuid === pack?.recipientTeamUuid && pack?.last?.status === 2) {
			postPackageStatus(pack, statuses.DELIVERED);
		}
	};

	useEffect(async () => {
		let mounted = true;
		if (mounted && reload === false) {
			let pack = await fetch(`${config.apiv1}/package/package.read/${params?.packageUuid}`);
			setPack(() => pack);
			setLoading(false);
		} else {
			setLoading(true);
			setReload(false);
		}
		return () => (mounted = false);
	}, [reload]);

	useEffect(async () => {
		let mounted = true;
		let scanned = new URLSearchParams(location.search).get('scanned');

		if (mounted && scanned == 'true') {
			handleQRScan(pack, teamState?.currentTeam?.teamUuid);
		}
		return () => (mounted = false);
	}, [pack, teamState]);

	return (
		<PageWrapper
			header={{
				icon: <TagIcon />,
				text: 'Package details',
				theme: 'sale',
				button1:
					teamState?.currentTeam?.teamUuid === pack.senderTeamUuid
						? {
								text: 'Edit package',
								cb: () =>
									showModal('editPackage', {
										callback: (e) => {
											if (e?.data) {
												setPack({ ...pack, ...e.data });
											}
										},
										data: pack,
										submitUrl: `${config.apiv1}/package/package.update/${params?.packageUuid}`,
										method: 'PATCH',
									}),
								style: 'secondary',
								width: 140,
						  }
						: undefined,
				button2: {
					text: 'Change status',
					cb: () =>
						showModal('changePackageStatus', {
							callback: (e) => {
								if (e?.data?.data) {
									setPack((pack) => ({
										...pack,
										locations: [...pack.locations, e.data.data],
									}));
								}
							},
							isSender: teamState?.currentTeam?.teamUuid === pack.senderTeamUuid,
							data: pack,
							submitUrl: `${config.apiv1}/location/location.create`,
							method: 'POST',
						}),
					style: 'secondary',
					width: 140,
				},
				button3: {
					cb: toggleDropdown,
					style: 'secondary',
					iconLeft: <PrintIcon />,
					iconRight: <ChevronDownIcon />,
					text: 'Print',
					dropdown: {
						open: dropdownOpen,
						items: [
							{
								text: 'Print package label',
								callback: () => {
									toggleDropdown();
									packLabelTemp(pack, getFilePath, { x: 20.8, y: 7.5 });
								},
							},
							{
								text: 'Print shipping slip',
								callback: () => {
									toggleDropdown();
									itemListTemp(pack, getFilePath, { x: 10, y: 10 + pack?.items?.length * 2 });
								},
							},
						],
					},
				},
				// button3: {
				// 	text: 'More',
				// 	cb: null,
				// 	style: 'secondary',
				// 	iconRight: <ThreeDotsHorizontalIcon />,
				// 	width: 140,
				// 	disabled: true,
				// },
			}}>
			<div className='packageDetails'>
				{!loading ? (
					<>
						<div className='row borderBottom'>
							<h3 className='marginRight-auto'>{pack?.packageRef}</h3>
							{pack?.courier ? <h3>{pack?.courier[0]?.companyName}</h3> : ''}
							<StopwatchCont pack={pack} />
							{pack?.user ? <MemberInfo user={pack?.user} /> : <></>}
						</div>
						<div className='row'>
							<div className='col'>
								{addressString ? (
									<p>
										<TargetIcon iconClass='dataIcon' />
										{addressString}
									</p>
								) : (
									<></>
								)}
								{pack?.recipient ? (
									<p>
										<AtIcon iconClass='dataIcon' />
										{pack?.recipient}
									</p>
								) : (
									<></>
								)}
							</div>
							<div className='col'>
								<p>
									<MapPinIcon iconClass='dataIcon' />
									{pack?.locations
										? `With ${parseTrackingStatus(
												pack?.locations[pack?.locations?.length - 1]?.status
										  )?.text?.toLocaleLowerCase()}`
										: ''}
								</p>
							</div>
							<div className='col'>
								<p>
									<CalendarIcon iconClass='dataIcon' />
									{dateConverter(pack?.quotedCollectionDateTime)}
								</p>
								<p>
									<TruckRightIcon iconClass='dataIcon' />
									{dateConverter(pack?.quotedDeliveryDateTime)}
								</p>
							</div>
							<div className='col'>
								{pack?.trackingNumber && (
									<p>
										<BarcodeIcon iconClass='dataIcon' />
										{pack?.trackingNumber}
									</p>
								)}
								{pack?.courier?.trackingUrl && (
									<a
										href={
											isValidURL(
												replaceString(
													pack?.courier?.trackingUrl,
													'{{trackingNumber}}',
													pack?.trackingNumber
												)
											)
												? replaceString(
														pack?.courier?.trackingUrl,
														'{{trackingNumber}}',
														pack?.trackingNumber
												  )
												: null
										}
										target='_blank'
										rel='noopener noreferrer'>
										<p>
											<LinkIcon iconClass='dataIcon' />
											{sliceString(
												replaceString(
													pack?.courier?.trackingUrl,
													'{{trackingNumber}}',
													pack?.trackingNumber
												),
												0,
												30,
												true
											)}
										</p>
									</a>
								)}
								{pack?.courier?.www && !pack?.courier?.trackingUrl && (
									<a
										href={isValidURL(pack?.courier?.www) ? pack?.courier?.www : null}
										target='_blank'
										rel='noopener noreferrer'>
										<p>
											<LinkIcon iconClass='dataIcon' />
											{pack?.courier?.www}
										</p>
									</a>
								)}
							</div>
						</div>
						<ItemsList listItems={pack?.items} pack={pack} reload={setReload} />
					</>
				) : (
					<Loading type='circle' size='medium' />
				)}
			</div>
		</PageWrapper>
	);
}

{
	/* {pack && (
				<div className='packageDetailsCont'>
					<div className='dataCont'>
						<div className='row'>
							<h4>Location: </h4>
							{pack.locations ? (
								<p>{parseTrackingStatus(currLocation.status).icon}</p>
							) : (
								<p>{parseTrackingStatus(0).icon}</p>
							)}
						</div>
						<div className='row'>
							<h4>Courier: </h4>
							<p>{filterCourier()?.companyName || 'None'}</p>
						</div>
						<div className='row'>
							<h4>Pickup: </h4>
							<p>{dateConverter(pack.quotedCollectionDateTime)}</p>
						</div>
						{currLocation.note === 'Delivered' && (
							<div className='row'>
								<h4>Status: </h4>
								<p>Delivered</p>
							</div>
						)}
						<div className='row marginTop-20'>
							{currLocation.note !== 'Delivered' && (
								<Button
									text='Remove package'
									size='sm'
									style='secondary'
									minWidth={140}
									onClick={() => {
										removePackPrompt(pack);
									}}
								/>
							)}
						</div>
						<div className='row marginTop-20'>
							<Button
								text='PDF'
								size='sm'
								style='secondary'
								minWidth={140}
								iconLeft={<FileIcon />}
								onClick={() => {
									packLabelTemp(pack, getFilePath, { x: 20.8, y: 7.5 });
								}}
							/>
						</div>
					</div>
					<h2>Package List</h2>
					<div className='cols'>
						<h4>Description</h4>
						<h4>Order Ref</h4>
						<h4>Note</h4>
						<h4></h4>
					</div>
					<div className='orderItems'>
						{pack?.items?.length > 0 ? (
							<>
								{pack.items.map((item, key) => {
									return (
										<div className='row' key={key}>
											<p>{item.itemDesc}</p>
											<p>{item.orderRef}</p>
											<p>{item.note}</p>
											<div className='btnRow'>
												<FileIcon
													onClick={() => {
														itemLabelTemp(item, getFilePath, true, { x: 6.5, y: 3.5 });
													}}
													iconClass='orderPageDiscIcon'
												/>
												{!currLocation.note === 'Delivered' && (
													<button
														className='btn3-secondary marginLeft-20'
														onClick={() => {
															removeItemPrompt(item.itemUuid);
														}}>
														Remove
													</button>
												)}
											</div>
										</div>
									);
								})}
							</>
						) : (
							<div className='message'>
								This package has no items. If you want to send something you have to do it from the
								Order Details page through one of your active orders.
							</div>
						)}
					</div>
				</div>
			)} */
}
