import React, { useContext, useEffect, useState } from 'react';
import GridItem from './GridItem';
import {
	AnalyticsIcon,
	AppIcon,
	BillGBPIcon,
	BoxesStackedIcon,
	CartBuyIcon,
	CartSellIcon,
	CustomerIcon,
	FileLinesIcon,
	MapPinIcon,
	ScalesIcon,
	SectionSignIcon,
	ShopIcon,
	SupplierIcon,
	TagIcon,
	TeamGearIcon,
	TeamPlusIcon,
	TruckRightIcon,
	WrenchIcon,
} from '../../components/SVGIcons/SVGIcons';
import GridItemSkeleton from './GridItemSkeleton';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { calcSkels } from '../../helpers';

export default function TeamManageGrid() {
	const settings = {
		fourWide: { minWidth: 1280, colCount: 4 },
		threeWide: { minWidth: 900, colCount: 3 },
		twoWide: { minWidth: 640, colCount: 2 },
		oneWide: { minWidth: 0, colCount: 1 },
	};
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const [skeletons, setSkeletons] = useState([]);

	const { teamState } = useContext(CurrentTeamContext);

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
			let gridItems = document.getElementsByClassName('gridItem');
			let skelCount = 0;
			let skels = [];

			// Calc skeletons
			if (window.innerWidth > settings.fourWide.minWidth) {
				let modulo = gridItems.length % settings.fourWide.colCount;
				if (modulo !== 0) {
					skelCount = settings.fourWide.colCount - modulo;
				}
			} else if (
				window.innerWidth < settings.fourWide.minWidth &&
				window.innerWidth > settings.threeWide.minWidth
			) {
				let modulo = gridItems.length % settings.threeWide.colCount;
				if (modulo !== 0) {
					skelCount = settings.threeWide.colCount - modulo;
				}
			} else if (
				window.innerWidth < settings.threeWide.minWidth &&
				window.innerWidth > settings.twoWide.minWidth
			) {
				let modulo = gridItems.length % settings.twoWide.colCount;
				if (modulo !== 0) {
					skelCount = settings.twoWide.colCount - modulo;
				}
			}

			for (let i = 0; i < skelCount; i++) {
				skels.push(<GridItemSkeleton />);
			}
			setSkeletons(() => skels);
		}

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			<div className='gridContainer'>
				<GridItem
					url={'manage/members'}
					header={'Members'}
					body={'Add members to your Team, manage them including their permissions.'}
					icon={<TeamPlusIcon iconClass='gridIcon' />}
					colorCode={'team'}
					disabled={teamState.isTeamOfOne}
				/>
				<GridItem
					url={'manage/team'}
					header={'Edit Team'}
					body={'Manage your Team’s registered address and details.'}
					icon={<TeamGearIcon iconClass='gridIcon' />}
					colorCode={'team'}
					disabled={teamState.isTeamOfOne}
				/>
				<GridItem
					url={'manage/addresses'}
					header={'Addresses'}
					body={'Manage your delivery addresses.'}
					icon={<MapPinIcon iconClass='gridIcon' />}
					colorCode={'team'}
				/>
				<GridItem
					url={''}
					header={'Billing'}
					body={'Change your subscription level and manage your payments.'}
					icon={<BillGBPIcon iconClass='gridIcon' />}
					colorCode={'team'}
					disabled
				/>
				<GridItem
					url={'manage/files'}
					header={'Files'}
					body={`Manage your team files.`}
					icon={<FileLinesIcon iconClass='gridIcon' />}
					colorCode={'team'}
				/>
				<GridItem
					url={teamState.isTeamOfOne ? '' : 'invoices'}
					header={'Sales'}
					body={'See your sales, invoices and ledger.'}
					icon={<TagIcon iconClass='gridIcon' />}
					colorCode={'selling'}
					disabled={teamState.isTeamOfOne}
				/>
				<GridItem
					url={'manage/inventory'}
					header={'Inventory'}
					body={'Manage your products that you have for sale and your shop.'}
					icon={<BoxesStackedIcon iconClass='gridIcon' />}
					colorCode={'selling'}
				/>
				<GridItem
					url={teamState.isTeamOfOne ? '' : `suppliers/${teamState.currentTeam.teamUuid}`}
					header={'Shop'}
					body={'Visit your current shop page.'}
					icon={<ShopIcon iconClass='gridIcon' />}
					colorCode={'selling'}
					disabled={teamState.isTeamOfOne || !teamState.currentTeam.isPublic}
				/>
				<GridItem
					url={'manage/customers'}
					header={'Customers'}
					body={`See who has ordered from you and has you in their 'trusted supplier' network.`}
					icon={<CustomerIcon iconClass='gridIcon' />}
					colorCode={'selling'}
					disabled
				/>
				<GridItem
					url={'packages'}
					header={'Dispatch'}
					body={`Manage your couriers and packages.`}
					icon={<TruckRightIcon iconClass='gridIcon' />}
					colorCode={'selling'}
					disabled={teamState.isTeamOfOne}
				/>
				<GridItem
					url={'manage/purchases'}
					header={'Purchase Orders'}
					body={`See your current purchases and purchase history.`}
					icon={<CartBuyIcon iconClass='gridIcon' />}
					colorCode={'purchase'}
					disabled
				/>
				<GridItem
					url={'manage/trusted'}
					header={'Trusted Suppliers'}
					body={`Manage your 'trusted suppliers'.`}
					icon={<SupplierIcon iconClass='gridIcon' />}
					colorCode={'purchase'}
				/>
				<GridItem
					url={''}
					header={'Analytics'}
					body={`Monitor your Team's trends with analytics.`}
					icon={<AnalyticsIcon iconClass='gridIcon' />}
					colorCode={'analytics'}
					disabled
				/>
				<GridItem
					url={'manage/apps'}
					header={'Apps'}
					body={`Discover how you can extend your Team by using Apps.`}
					icon={<AppIcon iconClass='gridIcon' />}
					colorCode={'apps'}
				/>
				<GridItem
					url={'manage/toolbox'}
					header={'Toolbox'}
					body={`Make your own forms and dockets.`}
					icon={<WrenchIcon iconClass='gridIcon' />}
					colorCode={'apps'}
					disabled={teamState.isTeamOfOne}
				/>
				<GridItem
					url={''}
					header={'Terms'}
					body={`Edit your Terms and Conditions.`}
					icon={<SectionSignIcon iconClass='gridIcon' />}
					colorCode={'legal'}
					disabled
				/>
				{skeletons && (
					<>
						{skeletons.map((skel, key) => {
							return (
								<React.Fragment key={key}>
									<GridItemSkeleton />
								</React.Fragment>
							);
						})}
					</>
				)}
			</div>
		</>
	);
}
