import React, { useContext, useEffect } from 'react';
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import DateInput from '../Inputs/DateInput';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { useForms } from '../../hooks/useForms';
import * as yup from 'yup';
import Button from '../Button';

export default function ChangePackageStatusForm({ options, hideModal }) {
	const { callback, submitUrl, method, data, isSender } = options || {};
	const formValidationSchema = yup.object().shape({
		status: yup.number(),
	});

	const { teamState } = useContext(CurrentTeamContext);

	let changePackageStatusMap = (formData) => {
		return {
			packageUuid: formData.packageUuid,
			status: formData.status,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'changePackageStatus',
		formValidationSchema,
		changePackageStatusMap,
		submitUrl,
		method
	);

	const handleFormData = (value, name) => {
		updateForm({ ...formData, [name]: value.value });
	};

	const handleSubmit = async () => {
		let success = await submitForm(null, `Package updated successfully`, callback);

		if (success === true) {
			hideModal();
		}
	};

	useEffect(() => {
		if (!isSender) {
			updateForm({
				packageUuid: data.packageUuid,
				status: 3,
			});
		} else if (data) {
			updateForm({
				packageUuid: data.packageUuid,
				status: data.locations ? data.locations[data.locations?.length - 1]?.status : undefined,
			});
		}
	}, []);
	return (
		<>
			{isSender ? (
				<>
					<form id='packageEdit'>
						<h2>Edit package</h2>
						<SelectInput
							text={'Status'}
							name={'status'}
							htmlOptions={[
								<option value={1}>Being prepared</option>,
								<option value={2}>In transit</option>,
								<option value={3}>Delivered</option>,
							]}
							callback={handleFormData}
							value={formData?.status || data?.locations[0]?.status}
							required
						/>
					</form>
					<div className='btnCont'>
						<Button
							size='md'
							style='primary'
							text='Save'
							onClick={handleSubmit}
							loading={reqLoading}
							disabled={formData?.status === data?.locations[data.locations.length - 1]?.status}
							minWidth={135}
						/>
					</div>
				</>
			) : (
				<div className='btnCont'>
					<Button
						size='md'
						style='primary'
						text='Mark as delivered'
						onClick={handleSubmit}
						loading={reqLoading}
						disabled={formData?.status === data?.locations[data.locations.length - 1]?.status}
						minWidth={135}
					/>
				</div>
			)}
		</>
	);
}
